import { Link } from 'gatsby-plugin-react-i18next'
import React, { Component } from 'react'
import '../styles/scss/news.scss'
import axios from 'axios'

export default class News extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'news',
                source: 'https://res.cloudinary.com/dg91u71x5/image/upload/v1677597825/d588c34b6e3cd4aa51169c80ee2c3042_s4p2ki.jpg',
                gallery: []
            }
        }
    }

    componentDidMount = () => {
        this.fetchAllArticles()
    }

    fetchAllArticles = () => {
        axios.get(`https://versemail.azurewebsites.net/news/`).then(response => {
            if (response?.data) {
                let articles = response.data?.reverse();

                this.setState({
                    page: {
                        ...this.state.page,
                        gallery: articles
                    }
                })
            }
        }).catch(error => {

        });
    }

    renderArticles = (articles) => {
        if (!articles?.length) return 'No news yet';

        return articles.map((article, index) => {
            return this.renderArticle(article, index)
        })
    }

    renderArticle = (article, index) => {
        return (
            <Link to={`/article/${article?.slug}`} className='article' key={index}>
                <div className='image' style={{backgroundImage: `url(${article?.source})`}}/>

                <div className='text'>
                    <h3>
                        {article?.title}
                    </h3>

                    <h4>
                        {article?.date}
                    </h4>

                    <p>
                        {article?.firsParagraph}
                    </p>
                </div>
            </Link>
        )
    }

    navigateArticle = (slug) => {
        
    }

    render() {
        return (
            <div className='news' id='news'>
                {this.renderArticles(this.state.page?.gallery)}
                <div className='load-btn'>
                    Load More
                </div>
            </div>
        )
    }
}
